import { useEffect, useState } from "react";
import "./homeSlider.css";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const HomeSlider = () => {
  const [active, setActive] = useState(0); // İlk aktif öğeyi 0 olarak ayarladık
  const [itemsCount, setItemsCount] = useState(2); // İki öğe olduğunu belirtmek için sabit değer
  const [slider, setSlider] = useState(null);
  const [loading, setLoading] = useState(true); // Yükleme durumu için state ekledik

  const handleNext = () => {
    setActive((prevActive) => (prevActive + 1) % itemsCount);
  };

  const handlePrev = () => {
    setActive((prevActive) => (prevActive - 1 + itemsCount) % itemsCount);
  };

  useEffect(() => {
    const changeSlider = () => {
      const items = document.querySelectorAll(".carousel .item");
      if (!items.length) return;

      items.forEach((item, index) => {
        if (index === active) {
          item.classList.add("active");
        } else {
          item.classList.remove("active");
        }
      });
    };

    const fetchSlider = async () => {
      try {
        const response = await axios.get(
          `https://genesis-api-l0zr.onrender.com/slider`
        );
        if (response.data && response.data.length > 0) {
          setSlider(response.data[0]);
        }
        setLoading(false); // Yükleme tamamlandığında loading state'ini false yap
      } catch (error) {
        console.error("Error fetching slider data", error);
        setLoading(false); // Hata durumunda loading state'ini false yap
      }
    };

    fetchSlider();

    changeSlider();
  }, [active]);

  if (loading) {
    return (
      <div className="loading-spinner">
        <p>Yükleniyor...</p>
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  if (!slider) {
    return <div>Slider verisi bulunamadı.</div>;
  }

  return (
    <>
      <main>
        <section className="carousel next">
          <div className="list">
            <article className={`item ${active === 0 ? "active" : ""}`}>
              <div
                className="main-content"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <div className="content">
                  <h2>{slider.title1 || "Başlık bulunamadı"}</h2>
                  <p className="description text-lg leading-8 mt-2">
                    {slider.description1 || "Açıklama bulunamadı"}
                  </p>
                </div>
              </div>
              <figure className="image">
                <div className="columns pb-0 mb-0 g2-container-mobile">
                  <figcaption>
                    <h1 className="g-blog__main__hero__header is-uppercase has-text-weight-bold g2-text-great-mobile my-2">
                      {slider.title1 || "Başlık bulunamadı"}
                    </h1>
                    <p className="description mt-2 g-blog__main__hero__description g2-text-small-mobile">
                      {slider.description1 || "Açıklama bulunamadı"}
                    </p>
                  </figcaption>
                  <img
                    src={slider.image1 || "/path/to/default-image.jpg"}
                    alt="Yüz Estetiği"
                    className="first-slider-img"
                  />
                </div>
              </figure>
            </article>
            <article className={`item ${active === 1 ? "active" : ""}`}>
              <div
                className="main-content"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <div className="content">
                  <h2>{slider.title2 || "Başlık bulunamadı"}</h2>
                  <p className="description mt-2">
                    {slider.description2 || "Açıklama bulunamadı"}
                  </p>
                </div>
              </div>
              <figure className="image">
                <div className="columns pb-0 mb-0 g2-container-mobile">
                  <figcaption>
                    <h1 className="g-blog__main__hero__header is-uppercase has-text-weight-bold g2-text-great-mobile my-2">
                      {slider.title1 || "Başlık bulunamadı"}
                    </h1>
                    <p className="description mt-2 g-blog__main__hero__description g2-text-small-mobile ">
                      {slider.description2 || "Açıklama bulunamadı"}
                    </p>
                  </figcaption>
                  <img
                    className="slider-image-2"
                    src={slider.image2 || "/path/to/default-image.jpg"}
                    alt="Vücut Şekillendirme"
                  />
                </div>
              </figure>
            </article>
          </div>
          <div className="arrows">
            <button id="prev" onClick={handlePrev}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <button id="next" onClick={handleNext}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </section>
      </main>
    </>
  );
};

export default HomeSlider;
