import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import TopButton from "./components/topButton/TopButton";
import Announcement from "./components/announcement/Announcement";
import Bloger from "./pages/blog/Bloger";
// import Contact from "./pages/contact/Contact";
import Reload from "./components/reload";
import BlogDetail from "./pages/blogDetail/BlogDetail";
import IV from "./components/services/ivTherapy/IV";
import Ozon from "./components/services/ozoneTherapy/Ozon";
import KokHucre from './components/services/kokHucre/kokHucre';
import Eksozom from "./components/services/eksozom/eksozom";
import Genclesme from "./components/services/genclesme/genclesme";
import { SearchProvider } from "./components/searchBar/SearchContext";
import About from "./pages/about/About";
import Genetik from "./components/services/genetik/genetik";
// import WpIcon from "./components/wpIcon/WpIcon";
import HairOperations from "./components/operations/hair/HairOperations";
import BodyOperations from "./components/operations/body/BodyOperations";
import SkinOperations from "./components/operations/skin/SkinOperations";
import FaceOperations from "./components/operations/face/FaceOperations";
import Reservation from "./components/reservation/Reservation";
import { Toaster } from "react-hot-toast";


function App() {
  return (
    <div className="App">
     <SearchProvider>
     <Router>
        <Reload />
        <Announcement />
        <Navbar />
        <Toaster position="top-right"/> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Bloger />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/iv" element={<IV />} />
          <Route path="/ozon" element={<Ozon />} />
          <Route path="/kokhucre" element={<KokHucre />} />
          <Route path="/eksozom" element={<Eksozom />} />
          <Route path="/genclesme" element={<Genclesme />} />
          <Route path="/genetik" element={<Genetik />} />
          <Route path="/about" element={<About />} />
          <Route path="/hair" element={<HairOperations />} />
          <Route path="/body" element={<BodyOperations />} />
          <Route path="/skin" element={<SkinOperations />} />
          <Route path="/face" element={<FaceOperations />} />
          <Route path="/reservation" element={<Reservation/>}/>
        </Routes>
        <TopButton />
        {/* <WpIcon/> */}
        <Footer />
      </Router>
     </SearchProvider>
    </div>
  );
}

export default App;
